import { UITask, useTasksForPatient } from "../../hooks/taskHooks";
import { useState } from "react";
import TaskCard from "./TaskCard";
import { PulseLoader } from "react-spinners";

interface PatientTaskListProps {
  patientId?: string;
}

export default function PatientTaskList({ patientId }: PatientTaskListProps) {
  const [loading, setLoading] = useState(true);
  const tasks = useTasksForPatient(patientId, setLoading);
  if (!tasks || tasks.length === 0) {
    return (
      <div className={"text-gray-900 dark:text-gray-50"}>
        {loading ? <PulseLoader loading={loading} /> : "No tasks found"}
      </div>
    );
  }
  return (
    <div>
      {tasks.map((task: UITask) => (
        <div>
          <TaskCard task={task} />
        </div>
      ))}
    </div>
  );
}
