import patientClient from "../rc360-client";
import pdfClient from "../pdf-client";

export function practiceSelected(): boolean {
  return !!localStorage.getItem("selectedPractice");
}

export async function findPatients(
  token: string,
  searchBy: string | null,
  sortBy: string | null,
  direction: string | null,
  page: number | null,
  pageSize: number | null,
  filterBy: string,
  filterValues: string
) {
  const params = new URLSearchParams();
  if (searchBy) {
    params.append("search", searchBy);
  }
  if (sortBy) {
    params.append("sort", sortBy);
    if (direction) {
      params.append("direction", direction);
    }
  }
  if (page) {
    params.append("page", String(page));
  }
  if (pageSize) {
    params.append("pageSize", String(pageSize));
  }
  if (filterBy && filterValues) {
    params.append("filterBy", filterBy);
    params.append("filterValues", filterValues);
  }

  const response = await patientClient.get(`/v2/patients`, {
    params: params,
    headers: {
      Authorization: `Bearer ${token.trim()}`,
      Practice: localStorage.getItem("selectedPractice"),
    },
  });
  return response.data;
}

export async function findPatientByMrn(
  mrn: string,
  token: string
): Promise<any> {
  const response = await patientClient.get(`/patients/${mrn}`, {
    headers: {
      Authorization: `Bearer ${token.trim()}`,
      Practice: localStorage.getItem("selectedPractice"),
    },
  });
  return response.data;
}

export async function findPatientDashboardByMrn(
  mrn: string,
  token: string
): Promise<any> {
  const response = await patientClient.get(`/patients/${mrn}/dashboard`, {
    headers: {
      Authorization: `Bearer ${token.trim()}`,
      Practice: localStorage.getItem("selectedPractice"),
    },
  });
  return response.data;
}

export async function findPatientStatsByMrn(
  mrn: string,
  token: string,
  statName: string
): Promise<any> {
  const response = await patientClient.get(`/patients/${mrn}/${statName}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.content;
}

export async function findEGFRForMrn(mrn: string, token: string): Promise<any> {
  const response = await patientClient.get(`/patients/${mrn}/combined-egfrs`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Practice: localStorage.getItem("selectedPractice"),
    },
  });
  return response.data;
}

export async function findLabDataByLookupCode(
  mrn: string,
  lookup_code: string,
  accessToken: string
) {
  const response = await patientClient.get(
    `/patients/${mrn}/labs/${lookup_code}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Practice: localStorage.getItem("selectedPractice"),
      },
    }
  );
  return response.data.content;
}

export async function findLabNames(mrn: string, accessToken: string) {
  const response = await patientClient.get(`/patients/${mrn}/labs`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Practice: localStorage.getItem("selectedPractice"),
    },
  });
  return response.data.content;
}

export async function downloadPatientPdf(
  mrn: string,
  accessToken: string,
  setDownloading: (value: ((prevState: boolean) => boolean) | boolean) => void
) {
  pdfClient
    .get(`/patients/${mrn}/pdf`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: "blob",
    })
    .then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", `${mrn}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setDownloading(false);
    });
}

export async function findVitals(mrn: string, accessToken: string) {
  const response = await patientClient.get(`/patients/${mrn}/vitals`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Practice: localStorage.getItem("selectedPractice"),
    },
  });
  return response.data;
}

export async function findLabSummaries(mrn: string, accessToken: string) {
  const response = await patientClient.get(`/patients/${mrn}/lab-summary`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}
