import React from "react";
import { PulseLoader } from "react-spinners";

export default function Loading(props: { loading: boolean }) {
  const darkMode =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;
  return (
    <div className="flex p-40 justify-center text-2xl">
      <PulseLoader
        aria-label="Loading..."
        size={25}
        color={darkMode ? "white" : "black"}
        loading={props.loading}
      />
    </div>
  );
}
