import { Patient } from "../../types/models";
import React, { useContext, useEffect } from "react";
import {
  Column,
  ColumnFiltersState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  PaginationState,
  RowData,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import Redacted from "../widgets/Redacted";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { SearchContext } from "../layout/AppLayout";

declare module "@tanstack/react-table" {
  // eslint-disable-next-line
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: "text" | "insurance_plans" | "programs" | "stage";
  }
}

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

const columnHelper = createColumnHelper<Patient>();

const columns = [
  columnHelper.display({
    id: "actions",
    cell: (props) => (
      <a
        href={"/patients/" + props.cell.row.original.id}
        className="text-indigo-600 hover:text-indigo-900 dark:text-indigo-100 dark:hover:text-indigo-300"
      >
        View
        <span className="sr-only">
          , {props.cell.row.original.last_name},{" "}
          {props.cell.row.original.first_name}{" "}
          {props.cell.row.original.middle_name}
        </span>
      </a>
    ),
  }),

  columnHelper.accessor((row) => row.last_name, {
    id: "last_name.keyword",
    cell: (info) => <Redacted>{info.getValue()}</Redacted>,
    header: () => <span>Last Name</span>,
    enableSorting: true,
    enableColumnFilter: false,
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("first_name", {
    cell: (info) => <Redacted>{info.getValue()}</Redacted>,
    header: () => <span>First Name</span>,
    enableSorting: false,
    enableColumnFilter: false,
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("mrn", {
    header: () => "MRN",
    cell: (info) => <Redacted>{info.renderValue()}</Redacted>,
    enableSorting: false,
    enableColumnFilter: false,
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("dob", {
    header: () => <span>DOB</span>,
    enableColumnFilter: false,
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("primary_insurance_plan", {
    id: "primary_insurance_plan.keyword",
    header: "Insurance",
    enableColumnFilter: true,
    enableSorting: true,
    meta: {
      filterVariant: "insurance_plans",
    },
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("enrolled_program", {
    id: "enrolled_program.keyword",
    header: "Program",
    enableColumnFilter: true,
    enableSorting: true,
    meta: {
      filterVariant: "programs",
    },
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("current_ckd_stage", {
    id: "current_ckd_stage.keyword",
    header: "Diagnosed CKD Stage",
    meta: {
      filterVariant: "stage",
    },
    enableColumnFilter: true,
    enableSorting: true,
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("last_encounter", {
    header: "Last Encounter",
    enableColumnFilter: false,
    enableSorting: true,
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("current_risk_score", {
    header: "Risk",
    enableColumnFilter: false,
    enableSorting: true,
    footer: (info) => info.column.id,
  }),
];

function Filter({
  column,
  plansForPractice,
}: {
  column: Column<any, unknown>;
  plansForPractice?: string[];
}) {
  const { filterVariant } = column.columnDef.meta ?? {};

  const columnFilterValue = column.getFilterValue();
  let values: string[] = [];
  switch (filterVariant) {
    case "insurance_plans":
      values = plansForPractice!;
      break;
    case "programs":
      values = ["CCM", "ESRD"];
      break;
    case "stage":
      values = [
        "Stage 5",
        "Stage 4",
        "Stage 3B",
        "Stage 3A",
        "Stage 2",
        "Stage 1",
      ];
      break;
    default:
      values = [];
  }
  return (
    <select
      onChange={(e) => column.setFilterValue(e.target.value)}
      value={columnFilterValue?.toString()}
    >
      <option value="">All</option>
      {values.map((plan) => (
        <option>{plan}</option>
      ))}
    </select>
  );
}

export default function PatientList(props: {
  patients: Patient[];
  plansForPractice: string[];
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  pagination: PaginationState;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  columnFilters: ColumnFiltersState;
  setColumnFilters: React.Dispatch<React.SetStateAction<ColumnFiltersState>>;
  rowCount?: number;
  practiceName?: string;
  setSearchContext: React.Dispatch<React.SetStateAction<string | null>>;
}) {
  const data = props.patients;
  const sorting = props.sorting;
  const setSorting = props.setSorting;
  const pagination = props.pagination;
  const setPagination = props.setPagination;
  const rowCount = props.rowCount;
  const columnFilters = props.columnFilters;
  const setColumnFilters = props.setColumnFilters;
  const setSearchContext = props.setSearchContext;
  const searchContext = useContext(SearchContext);

  const table = useReactTable<Patient>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    state: {
      columnFilters,
      sorting,
      pagination,
    },
    rowCount: rowCount,
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });
  useEffect(() => {
    table.resetPagination();
  }, [props.practiceName, props.columnFilters, table]);

  return (
    <div className="px-4 sm:px-6 lg:px-8 mt-6">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
            {!searchContext || searchContext === "" ? (
              <span></span>
            ) : (
              <span>
                Search Results for:{" "}
                <span className={"text-blue-600 dark:text-blue-300 font-bold"}>
                  "{searchContext}"
                </span>{" "}
                <button
                  onClick={() => {
                    setSearchContext(null);
                  }}
                  className={"inline-flex"}
                >
                  <XMarkIcon className="w-4 h-4" aria-hidden="true"></XMarkIcon>
                </button>
              </span>
            )}
          </h1>
        </div>
      </div>
      <div className="mt-8 flow-root ">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300 mb-5">
                <thead className="bg-gray-50 dark:bg-slate-500">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          scope="col"
                          className="py-3.5 pl-0 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white sm:pl-0"
                        >
                          {header.isPlaceholder ? null : (
                            <button
                              className={
                                header.column.getCanSort()
                                  ? "group inline-flex"
                                  : ""
                              }
                              onClick={header.column.getToggleSortingHandler()}
                              title={
                                header.column.getCanSort()
                                  ? header.column.getNextSortingOrder() ===
                                    "asc"
                                    ? "Sort ascending"
                                    : header.column.getNextSortingOrder() ===
                                      "desc"
                                    ? "Sort descending"
                                    : "Clear sort"
                                  : undefined
                              }
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: (
                                  <ChevronUpIcon
                                    className="ml-2 h-5 w-5 rounded text-gray-400 group-hover:visible group-focus:visible"
                                    aria-hidden="true"
                                  />
                                ),
                                desc: (
                                  <ChevronDownIcon
                                    className="ml-2 h-5 w-5 rounded text-gray-400 group-hover:visible group-focus:visible"
                                    aria-hidden="true"
                                  />
                                ),
                              }[header.column.getIsSorted() as string] ?? null}
                            </button>
                          )}
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter
                                column={header.column}
                                plansForPractice={props.plansForPractice}
                              />
                            </div>
                          ) : null}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white dark:bg-slate-700">
                  {table.getRowModel().rows.map((row) => (
                    <tr key={row.id} className="">
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          className="whitespace-nowrap px-1 py-2 text-sm text-gray-500 dark:text-gray-100"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>

              <nav className="flex min-w-full items-center justify-between px-4 sm:px-0">
                <div className="-mt-px flex w-0 flex-1">
                  <button
                    onClick={() => table.previousPage()}
                    className={classNames(
                      table.getCanPreviousPage()
                        ? ""
                        : "no-underline pointer-events-none opacity-50 cursor-not-allowed",
                      "inline-flex items-center pr-1 pt-4 text-sm font-medium text-gray-500  hover:text-gray-700"
                    )}
                  >
                    <ArrowLongLeftIcon
                      className="mr-3 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    Previous
                  </button>
                </div>
                <div className="hidden md:-mt-px md:flex text-gray-500 dark:text-gray-100 text-sm">
                  Page {table.getState().pagination.pageIndex + 1} of{" "}
                  {table.getPageCount().toLocaleString()}
                </div>
                <div className="-mt-px flex w-0 flex-1 justify-end">
                  <button
                    onClick={() => table.nextPage()}
                    className={classNames(
                      table.getCanNextPage()
                        ? ""
                        : "no-underline pointer-events-none opacity-50 cursor-not-allowed",
                      "inline-flex items-center  border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 "
                    )}
                  >
                    Next
                    <ArrowLongRightIcon
                      className="ml-3 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
