import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LandingPage from "./routes/landingPage";
import ErrorPage from "./error-page";
import Patients from "./routes/patients";
import PatientDetail from "./routes/patientdetail";
import { MsalProvider } from "@azure/msal-react";
import AppLayout from "./components/layout/AppLayout";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalInstance } from "./index";
import Tasks from "./routes/tasks";
import TaskDetail from "./routes/taskDetail";

interface CreateRouter {
  msalInstance: PublicClientApplication;
}

const useTasks = process.env["REACT_APP_RC_360_USE_TASKS"] === "true";

const createRouter = ({ msalInstance }: CreateRouter) => {
  let routes = [
    {
      path: "/",
      element: <LandingPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/patients",
      element: <AppLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <Patients />,
        },
        {
          path: ":patientId",
          element: <PatientDetail />,
          errorElement: <ErrorPage />,
        },
      ],
    },
  ];
  if (useTasks) {
    routes = routes.concat({
      path: "/tasks",
      element: <AppLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <Tasks />,
        },
        {
          path: ":taskId",
          element: <TaskDetail />,
          errorElement: <ErrorPage />,
        },
      ],
    });
  }
  return createBrowserRouter(routes);
};

const App = ({ instance }: any) => {
  return (
    <MsalProvider instance={instance}>
      <RouterProvider router={createRouter({ msalInstance })} />
    </MsalProvider>
  );
};

export default App;
