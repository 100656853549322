"use client";

import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";

interface DrawerProps {
  title: string;
  showDrawer: boolean;
  setShowDrawer: (value: ((prevState: boolean) => boolean) | boolean) => void;
  children: React.ReactNode;
}

export default function Drawer({
  title,
  showDrawer,
  setShowDrawer,
  children,
}: DrawerProps) {
  return (
    <Dialog open={showDrawer} onClose={setShowDrawer} className="relative z-10">
      <div className="fixed inset-y-0" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-20 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto bg-gray-200 dark:bg-gray-800 dark:border-white dark:border-8  w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full  flex-col overflow-y-scroll  py-6 shadow-xl">
                <div className="px-4 sm:px-6 ">
                  <div className="flex items-start justify-between">
                    <DialogTitle className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-50">
                      {title}
                    </DialogTitle>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        onClick={() => setShowDrawer(false)}
                        className="relative rounded-md  text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="relative mt-6 flex-1 px-4 sm:px-6">
                  {children}
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
