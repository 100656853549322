import { DataSeries, Patient } from "../../types/models";
import React from "react";
import BubbleChart from "../widgets/BubbleChart";
import { ResponsiveContainer } from "recharts";

export default function Medications(props: {
  patient: Patient;
  series: DataSeries[];
}) {
  const rowDescription = (row: any): string => {
    return row.medication_name;
  };
  return (
    <ResponsiveContainer width="100%" minHeight={100}>
      <BubbleChart
        name={"Medications"}
        patient={props.patient}
        rowDescription={rowDescription}
        rows={props.series}
      />
    </ResponsiveContainer>
  );
}
