import {
  Diagnosis,
  Encounter,
  Medication,
  NameValue,
  Patient,
} from "../../types/models";
import React from "react";

interface SummaryRow {
  encounter_date: string;
  medications: Medication[];
  diagnostics: Diagnosis[];
  encounters: Encounter[];
}

interface SummaryRows {
  [key: string]: SummaryRow;
}

export default function SummaryPivotTable(props: {
  patient: Patient;
  encounterSeries: Encounter[];
  medicationSeries: Medication[];
  diagnosticSeries: Diagnosis[];
}) {
  const items = {} as SummaryRows;
  props.encounterSeries.forEach((encounter: Encounter) => {
    encounter.data.forEach((nv: NameValue) => {
      if (!items[nv.name]) {
        items[nv.name] = {
          encounter_date: nv.name,
          medications: [],
          diagnostics: [],
          encounters: [],
        };
      }
      const summaryRow = items[nv.name];
      if (nv.value) {
        summaryRow.encounters.push(encounter);
      }
    });
  });
  props.medicationSeries.forEach((medication) => {
    medication.data.forEach((nv: NameValue) => {
      if (!items[nv.name]) {
        items[nv.name] = {
          encounter_date: nv.name,
          medications: [],
          diagnostics: [],
          encounters: [],
        };
      }
      const summaryRow = items[nv.name];
      if (nv.value) {
        summaryRow.medications.push(medication);
      }
    });
  });
  props.diagnosticSeries.forEach((diagnosis) => {
    diagnosis.data.forEach((nv: NameValue) => {
      if (!items[nv.name]) {
        items[nv.name] = {
          encounter_date: nv.name,
          medications: [],
          diagnostics: [],
          encounters: [],
        };
      }
      const summaryRow = items[nv.name];
      if (nv.value) {
        summaryRow.diagnostics.push(diagnosis);
      }
    });
  });
  const rows = [] as SummaryRow[];
  for (let key in items) {
    rows.push(items[key]);
  }
  rows.sort((o1, o2) => {
    if (o1.encounter_date < o2.encounter_date) {
      return 1;
    } else if (o1.encounter_date > o2.encounter_date) {
      return -1;
    }
    return 0;
  });
  return (
    <>
      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300 border-collapse border print:border-gray-500">
            <caption className="screen:hidden print:visible">
              Encounter Summary
            </caption>
            <thead className="bg-gray-50 dark:bg-slate-500">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 border print:border-gray-500 text-left text-sm print:text-xs font-semibold text-gray-900 dark:text-white"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 border print:border-gray-500 text-left text-sm print:text-xs font-semibold text-gray-900 dark:text-white"
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 border print:border-gray-500 text-left text-sm print:text-xs font-semibold text-gray-900 dark:text-white"
                >
                  Patient Class
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 border print:border-gray-500 text-left text-sm print:text-xs font-semibold text-gray-900 dark:text-white"
                >
                  Visit Reason
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 border print:border-gray-500 text-left text-sm print:text-xs font-semibold text-gray-900 dark:text-white"
                >
                  Medications
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 border print:border-gray-500 text-left text-sm print:text-xs font-semibold text-gray-900 dark:text-white"
                >
                  Diagnoses
                </th>
              </tr>
            </thead>
            <tbody>
              {rows.map((item: SummaryRow, rowIndex) => (
                <tr key={`summary-${rowIndex}`}>
                  <td className="py-3.5 pl-4 pr-3 border print:border-gray-500 text-left text-sm print:text-xs text-gray-900 dark:text-white">
                    {item.encounter_date}
                  </td>
                  <td className="py-3.5 pl-4 pr-3 border print:border-gray-500 text-left text-sm print:text-xs text-gray-900 dark:text-white">
                    <ul>
                      {item.encounters.map(
                        (encounter: Encounter, encounterIndex) => (
                          <li key={`type-${encounterIndex}`}>
                            {encounter.encounter_type}
                          </li>
                        )
                      )}
                    </ul>
                  </td>
                  <td className="py-3.5 pl-4 pr-3 print:border-gray-500 text-left text-sm print:text-xs border text-gray-900 dark:text-white">
                    <ul>
                      {item.encounters.map(
                        (encounter: Encounter, encounterIndex) => (
                          <li key={`patient-class-${encounterIndex}`}>
                            {encounter.patient_class}
                          </li>
                        )
                      )}
                    </ul>
                  </td>
                  <td className="py-3.5 pl-4 pr-3 print:border-gray-500 text-left text-sm print:text-xs border text-gray-900 dark:text-white">
                    <ul>
                      {item.encounters.map(
                        (encounter: Encounter, encounterIndex) => (
                          <li key={`reason-${encounterIndex}`}>
                            {encounter.reason}
                          </li>
                        )
                      )}
                    </ul>
                  </td>
                  <td className="py-3.5 pl-4 pr-3 border print:border-gray-500 text-left text-sm print:text-xs text-gray-900 dark:text-white">
                    <ul>
                      {item.medications.map(
                        (medication: Medication, medicationIndex) => (
                          <li key={`medication-name-${medicationIndex}`}>
                            {medication.medication_name}
                          </li>
                        )
                      )}
                    </ul>
                  </td>
                  <td className="py-3.5 pl-4 pr-3 border print:border-gray-500 text-left text-sm print:text-xs text-gray-900 dark:text-white">
                    <ul>
                      {item.diagnostics.map(
                        (diagnosis: Diagnosis, diagnosisIndex) => (
                          <li key={`diagnosis-${diagnosisIndex}`}>
                            {diagnosis.description}
                          </li>
                        )
                      )}
                    </ul>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
