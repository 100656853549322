import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./redacted.css";

import {
  Configuration,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import App from "./App";

const useTasks = process.env["REACT_APP_RC_360_USE_TASKS"] === "true";

const msalConfig: Configuration = {
  auth: {
    redirectUri: window.location.origin + (useTasks ? "/tasks" : "/patients"),
    clientId: "3d0deb42-0540-4146-a883-89c160df146e",
    authority:
      "https://login.microsoftonline.com/c43dd737-e077-4689-910d-2f3986e84519\n",
  },
};
export const msalInstance = new PublicClientApplication(msalConfig);

if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

/**
 * To set an active account after the user signs in, register an event and listen for LOGIN_SUCCESS and LOGOUT_SUCCESS. For more,
 * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
 */
msalInstance.addEventCallback((event) => {
  // @ts-ignore
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    // @ts-ignore
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const rootElement = document.getElementById("root") as HTMLElement;

ReactDOM.createRoot(rootElement).render(<App instance={msalInstance} />);
