import patientClient from "../rc360-client";

export async function findPractices(accessToken: string) {
  const response = await patientClient.get(`/fhir/r4/locations`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}
