import React, { useState } from "react";
import { UITask } from "../../hooks/taskHooks";
import NoteWidget from "../notes/NoteWidget";
import TaskDetails from "./TaskDetails";

export default function TaskDashboard(props: { task: UITask; taskId: string }) {
  const task = props.task! as UITask;
  const [lastModified, setLastModified] = useState(task.lastModified);

  if (!props.task) {
    return <div></div>;
  }

  return (
    <div className="container mx-auto px-5 py-7">
      <form>
        <div className="flex">
          <div className="flex-none w-96">
            <TaskDetails
              task={task}
              setLastModified={setLastModified}
              lastModified={lastModified}
            />
          </div>
          <div className="flex-auto">
            <NoteWidget task={task} setLastModified={setLastModified} />
          </div>
        </div>
      </form>
    </div>
  );
}
