import { DataSeries, Encounter, Patient } from "../../types/models";
import React from "react";
import BubbleChart from "../widgets/BubbleChart";

export default function Encounters(props: {
  patient: Patient;
  series: DataSeries[];
}) {
  const rowDescription = (encounter: Encounter): string => {
    return `${encounter.patient_class} - ${encounter.encounter_type} - ${encounter.reason}`;
  };
  return (
    <BubbleChart
      name={"Encounters"}
      patient={props.patient}
      rowDescription={rowDescription}
      rows={props.series}
    />
  );
}
