import {
  CompositePatient,
  Diagnosis,
  Encounter,
  Medication,
} from "../../types/models";
import { useSeriesData } from "../../hooks/patientHooks";
import HorizontalPatientDisplay from "./HorizontalPatientDisplay";
import EGFRGraph from "./EGFRGraph";
import VitalsGraphs from "./VitalsGraphs";
import Tabs from "../widgets/Tabs";
import MultiLabControl from "./MultiLabControl";
import LabSummary from "./LabSummary";
import Diagnoses from "./Diagnoses";
import Encounters from "./Encounters";
import Medications from "./Medications";
import SummaryPivotTable from "./SummaryPivotTable";
import React from "react";

const tabs = [
  { name: "Labs", href: "#", current: true },
  { name: "Lab Summary", href: "#", current: false },
  { name: "Diagnoses", href: "#", current: false },
  { name: "Encounters", href: "#", current: false },
  { name: "Medications", href: "#", current: false },
  { name: "Summary", href: "#", current: false },
];

export default function PatientDashboard(props: {
  patientDashboard: CompositePatient | null;
  patientId: string | undefined;
}) {
  const diagnosisSeries = useSeriesData(
    props.patientId!,
    "diagnoses"
  ) as Diagnosis[];
  const encounterSeries = useSeriesData(
    props.patientId!,
    "encounters"
  ) as Encounter[];
  const medicationSeries = useSeriesData(
    props.patientId!,
    "medications"
  ) as Medication[];

  if (!props.patientDashboard) {
    return <div></div>;
  }
  return (
    <>
      <HorizontalPatientDisplay patientDashboard={props.patientDashboard} />
      <div className="lg:flex">
        <div className="flex-auto">
          <div className="flex mb-10">
            <div className="basis-1/2">
              <EGFRGraph data={props.patientDashboard.combined_egfrs} />
            </div>
            <VitalsGraphs
              vitals={props.patientDashboard.vitals}
              containerClassName="basis-1/2"
            />
          </div>
        </div>
      </div>
      <div className="mb-20 pb-20">
        <Tabs tabs={tabs}>
          <MultiLabControl patient={props.patientDashboard.patient} />
          <LabSummary patient={props.patientDashboard.patient} />
          <Diagnoses
            patient={props.patientDashboard.patient}
            series={diagnosisSeries}
          />
          <Encounters
            patient={props.patientDashboard.patient}
            series={encounterSeries}
          />
          <Medications
            patient={props.patientDashboard.patient}
            series={medicationSeries}
          />
          <SummaryPivotTable
            patient={props.patientDashboard.patient}
            diagnosticSeries={diagnosisSeries}
            encounterSeries={encounterSeries}
            medicationSeries={medicationSeries}
          />
        </Tabs>
      </div>
    </>
  );
}
