import React, { ReactNode } from "react";
import { classNames } from "../../utils";

export default function BasicButton(props: {
  children: ReactNode;
  onClick?: any;
  disabled?: boolean;
}) {
  const colors = props.disabled
    ? "bg-gray-500 hover:bg-gray-400"
    : "bg-indigo-500 hover:bg-indigo-400 focus-visible:outline-indigo-500";
  return (
    <button
      onClick={props.onClick ? props.onClick : undefined}
      type={"button"}
      disabled={props.disabled}
      className={classNames(
        colors,
        "ml-3 inline-flex items-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
      )}
    >
      {props.children}
    </button>
  );
}
