import { UITask } from "../../hooks/taskHooks";
import TaskCard from "./TaskCard";
import Draggable from "./Draggable";
import DroppableLane from "./DroppableLane";
import { useEffect, useState } from "react";

export default function TaskLane(props: {
  id: string;
  name: string;
  tasks: UITask[];
  sublanes: string[];
}) {
  const [obscureSublane, setObscureSublane] = useState<boolean>(false);
  useEffect(() => {
    setObscureSublane(props.sublanes.length === 1);
  }, [props.sublanes]);
  return (
    <div className="w-60 m-3 h-64">
      <div className="text-center mb-10 font-bold">{props.name}</div>
      {props.sublanes.map((sublane) => (
        <DroppableLane
          key={sublane}
          id={sublane}
          obscureSublane={obscureSublane}
        >
          <div className="min-h-64">
            {props.tasks
              .filter((task) => task.status === sublane)
              .map((task) => (
                <Draggable id={task.id} key={task.id}>
                  <TaskCard task={task} />
                </Draggable>
              ))}
          </div>
        </DroppableLane>
      ))}
    </div>
  );
}
