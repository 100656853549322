import React from "react";

export default function Timestamp(props: { time?: string | Date | null }) {
  if (!props.time) {
    return <></>;
  }
  const theDate =
    props.time instanceof Date ? props.time : new Date(props.time);
  return (
    <time dateTime={theDate.toISOString()}>
      {theDate.toLocaleDateString() + " " + theDate.toLocaleTimeString()}
    </time>
  );
}
