import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { apiRequest } from "../services/baseApi";
import { findPlansForPractice } from "../services/plansApi";

export function usePlans(practiceName: string) {
  const authContext = useMsal();
  const [plans, setPlans] = useState<string[]>([]);

  useEffect(() => {
    apiRequest({
      whenAcquired: (accessToken: string) => {
        findPlansForPractice(accessToken).then((data) => {
          setPlans(data);
        });
      },
      authContext: authContext,
    }).then(() => {});
  }, [authContext, practiceName]);
  return plans;
}
