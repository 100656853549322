import { Patient } from "../../types/models";
import Redacted from "./Redacted";

export default function PatientName(props: { patient: Patient }) {
  return (
    <div className="inline-block">
      <Redacted>
        {props.patient.last_name}, {props.patient.first_name}{" "}
        {props.patient.middle_name}
      </Redacted>
    </div>
  );
}
