import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { apiRequest } from "../services/baseApi";
import { useMsal } from "@azure/msal-react";
import { findPractices } from "../services/practiceApi";
import { Bundle, BundleEntry, Location } from "../types/fhir";
import { LocationItem } from "../types/models";

export function usePractices(
  setPracticeName: Dispatch<SetStateAction<string | null>>
) {
  const authContext = useMsal();
  const [practices, setPractices] = useState([] as LocationItem[]);

  useEffect(() => {
    apiRequest({
      whenAcquired: (accessToken: string) => {
        let selectedPractice = localStorage.getItem("selectedPractice") || "";
        findPractices(accessToken).then((locations: Bundle) => {
          if (!selectedPractice) {
            const location: Location = locations.entry![0]
              .resource! as unknown as Location;
            selectedPractice = location.id;
            localStorage.setItem("selectedPractice", selectedPractice);
            localStorage.setItem("selectedPracticeName", location.name);
          }
          let entries: LocationItem[] = [];
          locations.entry!.forEach((item: BundleEntry): void => {
            const location: Location = item.resource as unknown as Location;
            let active = location.id === selectedPractice;
            if (active) {
              setPracticeName(location.name);
            }
            entries.push({
              id: location.id,
              name: location.name,
              active: active,
            });
          });
          setPractices(entries);
        });
      },
      authContext: authContext,
    }).then(() => {});
  }, [authContext, setPracticeName]);
  return practices;
}
