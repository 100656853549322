import React, { useEffect, useState } from "react";
import Message from "./Message";
import { PatientMessage } from "../../types/models";
import { apiRequest } from "../../services/baseApi";
import {
  findMessagesForPatient,
  sendPatientMessage,
} from "../../services/messagesApi";
import { useMsal } from "@azure/msal-react";

interface MessagesProps {
  patientId: string;
  patientCell: string | undefined;
}

export default function Messages({ patientId, patientCell }: MessagesProps) {
  const [messages, setMessages] = useState<PatientMessage[]>([]);
  const [message, setMessage] = useState("");
  const authContext = useMsal();

  useEffect(() => {
    apiRequest({
      whenAcquired: (accessToken: string) => {
        findMessagesForPatient(accessToken, patientId).then((messages: any) => {
          setMessages(messages.messages);
        });
      },
      authContext: authContext,
    }).then((r) => {});
  }, [authContext, setMessages, patientId]);

  function detectEnter(event: any) {
    if (event.code === "Enter" && !event.shiftKey) {
      event.preventDefault();
      const newItem: PatientMessage = {
        date_sent: new Date().toISOString(),
        patient_mrn: patientId,
        message_type: "custom",
        phone_cell: patientCell!,
        raw_message: event.target.value,
      };
      apiRequest({
        whenAcquired: (accessToken: string) => {
          sendPatientMessage(accessToken, newItem).then((response: any) => {
            findMessagesForPatient(accessToken, patientId).then(
              (messages: any) => {
                setMessages(messages.messages);
              }
            );
          });
        },
        authContext: authContext,
      }).then((r) => {});
      //
      //
      // setMessages(messages.concat([newItem]));
      setMessage("");
    }
  }

  return (
    <div className="ml-8 mr-8 mt-10">
      <div>
        {messages.map((message, index) => (
          <Message key={index} message={message} />
        ))}
      </div>
      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 ">
        <div className="col-span-full">
          <div className="mt-2">
            <textarea
              name="sendMessage"
              placeholder="Type here to send a message"
              rows={4}
              onKeyUp={detectEnter}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
