import { Lab, Patient } from "../../types/models";
import React, { useEffect, useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useLabResults } from "../../hooks/patientHooks";

export default function LabGraphControl(props: {
  patient: Patient;
  labIndex: number;
  labCategory: string;
  labs: Lab[];
}) {
  function selectFirst(labs: Lab[], labIndex: number): Lab | null {
    if (labs && labs.length > labIndex) {
      return labs[labIndex];
    } else if (labs.length > 0) {
      return labs[labs.length - 1];
    }
    return null;
  }

  const firstLab = selectFirst(props.labs, props.labIndex);
  const [selectedLab, setSelectedLab] = useState(firstLab);
  const labResults = useLabResults(props.patient.id, selectedLab);

  useEffect(() => {
    (async () => {
      setSelectedLab(firstLab);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.labs, props.labCategory]);

  return (
    <div className="mr-5">
      <div className="pb-10 pr-10 pl-10">
        <label
          htmlFor="labSelection"
          className="block text-sm font-medium leading-6 text-gray-900 sr-only"
        >
          Lab
        </label>
        <select
          id="labSelection"
          name="labSelection"
          onInput={(e) => {
            const newLabCode = (e.target as any).value;
            setSelectedLab(
              props.labs.find((item) => item.lookup_code === newLabCode) || null
            );
          }}
          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        >
          {props.labs.map((lab: Lab, index: number) => (
            <option
              value={lab.lookup_code}
              key={`lab-option-${lab.lookup_code}-${props.labIndex}-${index}`}
              selected={
                (selectedLab &&
                  selectedLab.lookup_code === lab.lookup_code) as boolean
              }
            >
              {lab.description}
            </option>
          ))}
        </select>
      </div>
      <ResponsiveContainer width="95%" height={400}>
        <LineChart
          width={200}
          height={400}
          data={labResults}
          syncId={"selectedDate"}
          syncMethod="value"
        >
          <Line
            type="monotone"
            dataKey="value"
            stroke="#8884d8"
            strokeWidth="3"
          />
          <CartesianGrid stroke="#ccc" />
          <Tooltip />
          <XAxis
            dataKey="result_date"
            allowDuplicatedCategory={false}
            tickMargin={10}
          />
          <YAxis dataKey="value" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
