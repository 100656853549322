import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Dispatch, Fragment, SetStateAction } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Practice } from "../../types/models";
import { usePractices } from "../../hooks/practiceHooks";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export default function PracticeSelection(props: {
  practiceName: string | null;
  setPracticeName: Dispatch<SetStateAction<string | null>>;
  setSearchContext: Dispatch<SetStateAction<string | null>>;
}) {
  const practices = usePractices(props.setPracticeName);
  const location = useLocation();
  const navigate = useNavigate();

  const selectPractice = (id: string, name: string) => {
    return (event: any) => {
      localStorage.setItem("selectedPractice", id);
      localStorage.setItem("selectedPracticeName", name);
      props.setSearchContext(null);
      props.setPracticeName(name);
      if (!location.pathname.endsWith("/patients")) {
        navigate("/patients", { replace: true });
      }
      practices.forEach((item: Practice): void => {
        item.active = item.id === id;
      });
    };
  };

  return (
    <div className=" inset-y-0 left-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md text-white px-3 py-2 text-sm font-semibold  shadow-sm ring-1 ring-inset ring-gray-900 hover:bg-gray-700">
            {props.practiceName}
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <ChevronDownIcon
              className="-mr-1 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {practices.map((item) => (
                <Menu.Item key={item.id}>
                  <span
                    onClick={selectPractice(item.id, item.name)}
                    className={classNames(
                      item.active ? "bg-gray-300" : "",
                      "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:cursor-pointer"
                    )}
                  >
                    {item.name}
                  </span>
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
