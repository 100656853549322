import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../components/widgets/Loading";
import { useTask } from "../hooks/taskHooks";
import TaskDashboard from "../components/tasks/TaskDashboard";

export default function TaskDetail() {
  const { taskId } = useParams();
  const [loading, setLoading] = useState(true);
  const task = useTask(taskId, setLoading);
  return (
    <>
      {loading || !task ? (
        <Loading loading={loading} />
      ) : (
        <TaskDashboard taskId={taskId!} task={task} />
      )}
    </>
  );
}
