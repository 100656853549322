import React from "react";
import { useDroppable } from "@dnd-kit/core";

export default function DroppableLane(props: {
  id: string;
  children: React.ReactNode;
  obscureSublane: boolean;
}) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
  });

  const classes = isOver
    ? "min-h-full bg-blue-200 border-8 rounded-lg border-2 border-gray-900 dark:border-gray-100 dark:bg-blue-800 p-2"
    : "min-h-full rounded-lg p-2";
  const sublaneClasses = props.obscureSublane
    ? "m-2 invisible"
    : "m-2 text-center uppercase font-light";
  return (
    <div ref={setNodeRef} className={classes}>
      <div className={sublaneClasses}>{props.id}</div>

      {props.children}
    </div>
  );
}
