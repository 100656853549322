import { IMsalContext } from "@azure/msal-react/dist/MsalContext";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import {
  AuthCodeMSALBrowserAuthenticationProvider,
  AuthCodeMSALBrowserAuthenticationProviderOptions,
} from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { Client } from "@microsoft/microsoft-graph-client";
import { User } from "../types/models";

const CARE_MANAGERS_ADMIN_GROUPS = (
  process.env["REACT_APP_RC_360_CARE_MANAGERS_ADMIN"] || ""
).split(",");

export function isCareManagerAdmin(authContext: IMsalContext): boolean {
  const claims = authContext.accounts[0].idTokenClaims;
  if (claims === undefined) {
    return false;
  }
  return (
    (claims!["groups"] as string[]).filter((group) =>
      CARE_MANAGERS_ADMIN_GROUPS.includes(group)
    ).length > 0
  );
}

function compareLastName(a: User, b: User): number {
  let partsA = a.displayName.split(" ");
  let partsB = b.displayName.split(" ");
  if (partsA.length > 1 && partsB.length > 1) {
    return partsA[1].localeCompare(partsB[1]);
  }
  return a.displayName.localeCompare(b.displayName);
}

export function useGroupMembers(groupId: string): User[] | undefined {
  const authContext = useMsal();
  const [getGroupMembers, setGroupMembers] = useState<User[]>();

  useEffect(() => {
    (async () => {
      const options: AuthCodeMSALBrowserAuthenticationProviderOptions = {
        account: authContext.accounts[0],
        interactionType: InteractionType.Redirect,
        scopes: [
          "user.read",
          "GroupMember.Read.All",
          "Directory.Read.All",
          "Group.Read.All",
        ],
      };
      const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
        authContext.instance as PublicClientApplication,
        options
      );

      const graphClient = Client.initWithMiddleware({
        authProvider,
      });
      let userDetails = await graphClient
        .api(`/groups/${groupId}/members`)
        .get();
      let users = userDetails.value.sort(compareLastName);
      setGroupMembers(users);
    })();
  }, [authContext, setGroupMembers, groupId]);
  return getGroupMembers;
}
