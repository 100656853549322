import LabGraphControl from "./LabGraphControl";
import React, { useEffect, useState } from "react";
import { useLabData } from "../../hooks/patientHooks";
import { Lab, Patient } from "../../types/models";

export default function MultiLabControl(props: { patient: Patient }) {
  const { labCategories, labTypes } = useLabData(props.patient.id);
  const [selectedLabTypes, setSelectedLabTypes] = useState([] as Lab[]);
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    setSelectedCategory(labCategories.length > 0 ? labCategories[0] : "");
  }, [labCategories]);

  useEffect(() => {
    (async () => {
      setSelectedLabTypes(
        labTypes.filter((lab) => {
          return lab.category === selectedCategory;
        })
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  return (
    <>
      <div className="print:hidden">
        <select
          id="labCategorySelection"
          name="labCategorySelection"
          onInput={(e) => {
            const newLabCategory = (e.target as any).value;
            setSelectedCategory(newLabCategory);
          }}
          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        >
          {labCategories.map((category: string, index: number) => (
            <option value={category} key={`lab-category-${category}-${index}`}>
              {category}
            </option>
          ))}
        </select>
      </div>

      <div className={"flex sm:flex-wrap print:hidden"}>
        <div className="lg:basis-1/3">
          <LabGraphControl
            patient={props.patient}
            labIndex={0}
            labs={selectedLabTypes}
            labCategory={selectedCategory}
          />
        </div>
        <div className="lg:basis-1/3">
          <LabGraphControl
            patient={props.patient}
            labIndex={1}
            labs={selectedLabTypes}
            labCategory={selectedCategory}
          />
        </div>
        <div className="lg:basis-1/3">
          <LabGraphControl
            patient={props.patient}
            labIndex={2}
            labs={selectedLabTypes}
            labCategory={selectedCategory}
          />
        </div>
      </div>
    </>
  );
}
