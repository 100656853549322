import messageClient from "./messageClient";
import { PatientMessage } from "../types/models";

export async function findMessagesForPatient(
  accessToken: string,
  patientMrn: string
) {
  const response = await messageClient.get(`/patients/${patientMrn}/messages`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Practice: localStorage.getItem("selectedPractice"),
    },
  });
  return response.data;
}

export async function sendPatientMessage(
  accessToken: string,
  message: PatientMessage
): Promise<any> {
  const response = await messageClient.post(`/messages`, message, {
    headers: {
      Authorization: `Bearer ${accessToken.trim()}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.data;
}
