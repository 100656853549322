import React, { Children, MouseEvent, ReactNode, useState } from "react";

type Tab = { name: string; href: string; current: boolean };
type PropsWithChildren<P> = P & { children?: ReactNode };

type TabProps = {
  tabs: Tab[];
};

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Tabs(props: PropsWithChildren<TabProps>) {
  const [selectedTab, setSelectedTab] = useState(0);
  const changeTab = (tabIndex: number): any => {
    return (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();
      setSelectedTab(tabIndex);
    };
  };
  return (
    <>
      <div className="mb-10">
        <div className="sm:hidden print:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            defaultValue={props.tabs.find((tab: Tab) => tab.current)!.name}
          >
            {props.tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block print:hidden">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {props.tabs.map((tab, index) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  onClick={changeTab(index)}
                  className={classNames(
                    selectedTab === index
                      ? "border-indigo-500 text-indigo-600 dark:text-indigo-300 "
                      : "border-transparent text-gray-500 dark:text-gray-100 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 py-4 px-1 text-xl font-medium no-underline"
                  )}
                  aria-current={selectedTab === index ? "page" : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div id="panels">
        {Children.map(props.children, (child, index) => (
          <div
            className={
              selectedTab === index ? "screen:visible" : "screen:hidden"
            }
          >
            {child}
          </div>
        ))}
      </div>
    </>
  );
}
