import React, {
  Fragment,
  MouseEvent,
  MouseEventHandler,
  useEffect,
  useState,
} from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, UserIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { Outlet, redirect, useLocation } from "react-router-dom";
import PracticeSelection from "./PracticeSelection";
// @ts-ignore
import SearchBar from "./SearchBar";

export const SearchContext = React.createContext(null as string | null);

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export default function AppLayout({ children }: any) {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const [practiceName, setPracticeName] = useState(
    localStorage.getItem("selectedPracticeName")
  );
  const location = useLocation();
  const [searchContext, setSearchContext] = useState(null as string | null);
  const taskCurrent = location.pathname.startsWith("/tasks");
  const useTasks = process.env["REACT_APP_RC_360_USE_TASKS"] === "true";
  const navigationItems = useTasks
    ? [
        { name: "Tasks", href: "/tasks", current: taskCurrent },
        { name: "Patients", href: "/patients", current: !taskCurrent },
      ]
    : [{ name: "Patients", href: "/patients", current: true }];
  const [navigation] = React.useState<any[]>(navigationItems);

  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      instance.loginRedirect({ scopes: [] }).then(() => {});
    }
  });

  const handleLogout: MouseEventHandler<HTMLAnchorElement> = (
    event: MouseEvent<HTMLElement>
  ) => {
    event.preventDefault();
    instance.logoutRedirect().then((r) => redirect("/"));
  };

  // @ts-ignore
  return (
    <SearchContext.Provider value={searchContext}>
      <div className="bg-white dark:bg-slate-800">
        <Disclosure as="nav" className="bg-gray-800 dark:bg-slate-800">
          {({ open }) => (
            <>
              <div className="mx-auto  px-2 sm:px-6 lg:px-8 print:hidden">
                <div className="relative flex h-16 items-center justify-between">
                  <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                    {/* Mobile menu button*/}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="flex items-center justify-center sm:items-stretch sm:justify-start">
                    <div className="flex flex-shrink-0 items-center">
                      <img
                        className="block h-8 w-auto lg:hidden"
                        src={process.env.PUBLIC_URL + "/rc360logo192.png"}
                        alt="Renal Care 360"
                      />
                      <img
                        className="hidden h-8 w-auto lg:block"
                        src={process.env.PUBLIC_URL + "/rc360logo192.png"}
                        alt="Renal Care 360"
                      />
                    </div>
                    <div className="hidden sm:ml-6 sm:block">
                      <div className="flex space-x-4">
                        {navigation.map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as={"a"}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "text-indigo-400"
                                : "text-gray-500 hover:bg-gray-700 hover:text-white",
                              "block rounded-md px-3 py-2 text-base font-medium no-underline"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}

                        <PracticeSelection
                          practiceName={practiceName}
                          setPracticeName={setPracticeName}
                          setSearchContext={setSearchContext}
                        />
                      </div>
                    </div>
                  </div>

                  <SearchBar
                    searchContext={searchContext}
                    setSearchContext={setSearchContext}
                  />

                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                          <span className="sr-only">Open user menu</span>
                          <UserIcon className="h-6 w-6 " aria-hidden="true" />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-6 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <div className={"px-4 py-2 text-sm"}>
                                {activeAccount == null
                                  ? ""
                                  : activeAccount.name}
                              </div>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="/"
                                onClick={handleLogout}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Sign out
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden">
                <div className="space-y-1 px-2 pb-3 pt-2">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current
                          ? "text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "block rounded-md px-3 py-2 text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                  <PracticeSelection
                    practiceName={practiceName}
                    setPracticeName={setPracticeName}
                    setSearchContext={setSearchContext}
                  />
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <AuthenticatedTemplate>
          <div className="mx-auto px-4 bg-white dark:bg-slate-800">
            <Outlet
              context={[practiceName, setPracticeName, setSearchContext]}
            />
          </div>
        </AuthenticatedTemplate>
      </div>
    </SearchContext.Provider>
  );
}
