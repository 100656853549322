import patientClient from "../rc360-client";

export async function findPlansForPractice(accessToken: string) {
  const response = await patientClient.get(`/plans`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Practice: localStorage.getItem("selectedPractice"),
    },
  });
  return response.data;
}
